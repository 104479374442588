import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Details = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section class="section shadow-sm lazyloaded" id="overview">
      <div class="theme-element timer-element">
        <picture>
          <img
            src="../images/bhandup/timer-left-element.svg"
            width="100%"
            alt="Element"
          />
        </picture>
      </div>
      <div class="theme-element timer-element-right">
        <picture>
          <img
            src="../images/bhandup/timer-right-element.svg"
            width="100%"
            alt="Element"
          />
        </picture>
      </div>
      <div class="over1">
        <h1 class="d-block section-heading color-primary text-capitalize" style={{marginBottom: "1.25rem"}}>
          Welcome to Adani Realty Thane
        </h1>
        <span class="head text-capitalize" style={{fontSize: "1.25rem"}}>
          At Teen Hath Naka, Thane (W)
        </span>
        <p>
          Adani Realty is set to unveil a 18-acre luxury residential project in
          Teen Haath Naka Thane West, offering 2, 3, and 4 BHK apartments.
          Adani’s Teen Hath Naka development blends modern amenities with
          nature-inspired living, creating a serene lifestyle amidst lush
          greenery.
          <span id="text" style={{ display: "none" }}>
            Strategically located, it ensures excellent connectivity to business
            hubs, schools, and healthcare centers, making it the perfect choice
            for a balanced urban lifestyle. Get In Touch Today to book your
            dream home in Thane’s newest landmark!
          </span>
        </p>
        {/* <button id="toggle" class="btn btn-link btn-sm more">
          Read More
        </button> */}
      </div>

      <p></p>
      <button
        class="chat-btn btn btn-info micro-form-btn effetMoveGradient enqModal download_brochure"
        onClick={handleShowModal}
      >
        <span class="d-inline-block mi mi-download mr-1 animated slideInDown infinite"></span>{" "}
        Download Brochure
      </button>
    </section>
  );
};

export default Details;
