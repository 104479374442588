/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Pricing = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="pricing">
      <span className="section-link"></span>
      <h2 className="head text-capitalize">
        Indicative Pricing For Adani Realty Thane
      </h2>
      <div className="row">
        <div className="col-md-8">
          <table className="table table-striped table-borderless border micro-price-table table-pricing">
            <thead>
              <tr>
                <th scope="col" className="border border-bottom-0 mb-w">
                  Typology
                </th>
                <th scope="col" className="border border-bottom-0 mb-w">
                  Carpet Area
                </th>
                <th
                  scope="col"
                  className="border border-bottom-0 border-right-0"
                >
                  {/* 10th Floor : All in */}
                  Price
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  2 BHK Elita{" "}
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  750 Sq. ft.
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  2 BHK Magna
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  850 Sq. ft.
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  3 BHK Elegante
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1057
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  3 BHK Splenda
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1088
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  3 BHK Imperia
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1214
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4 BHK Platina
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1930
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4 BHK Luxuria
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  2235
                  <small className="d-inline-block d-md-none">
                    (Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="col-md-4" onClick={handleShowModal}>
          <div className="at-property-item shadow-sm border border-grey mt-1">
            <div className="at-property-img lazyloaded" data-expand="-1">
              <img
                data-sizes="auto"
                className="w-100 lazyautosizes ls-is-cached lazyloaded ls-inview"
                width="320"
                height="174"
                src="images/mvk/costing.jpg"
                alt="Adani Realty Thane"
              />
              <div className="at-property-overlayer"></div>{" "}
            
            </div>
            <div className="at-property-dis effetGradient">
              <h5>Complete Costing Details</h5>{" "}
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Pricing;
