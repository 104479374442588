import React from "react";
import { useGlobalContext } from "./context/globalContext";
import "../index.css"
import NewForm from "./NewForm";

const InfoBox = () => {
  const { handleShowModal } = useGlobalContext();

  return (
    <div>
      <div className="info-box overflow-hidden">
        <span className="pro-status">Pre-Launch</span>
        <h1 className="pro-title">Adani Realty Thane New Launch</h1>
        <div>
          <h3 className="pro-dev">At Teen Hath Naka, Thane West</h3>
        </div>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer">
            <span className="offer-text">
              Low Density Development Preference in Early Choice Of Inventory
              Exclusive for EOI Clients ONLY Book with EOI Amount ₹2 Lacs*
            </span>
          </span>
        </span>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer" style={{ color: "black" }}>
            <span className="offer-text" style={{ padding: "0px" }}>
              {/* Site Visits By Invitation */}
              Avail Exclusive Pre-Launch Benefits
            </span>
          </span>
        </span>
        <span class="desktop-only d-none pro-tag-line" style={{fontSize: window.innerWidth > 768 ? "1.1rem" : "1.4rem"}}>
          Spacious <b> 2, 3 &amp; 4 BHK</b> Starts At
        </span>{" "}
        <span class="pro-price desktop-only d-none">
          {/* <span class="strike">₹ 2.16 Cr</span> */}
          <span>₹ 1.82 Cr</span>
          <small style={{ fontSize: "1rem" }}> Onwards.</small>
        </span>
        <span className="d-block"></span>
        {/* <span class="cd-head">Avail Exclusive Pre-Launch Benefits</span> */}
        <button
          className="desktop-only d-none btn btn-info micro-form-btn enqModal effetMoveGradient effectScale"
          onClick={handleShowModal}
        >
          Express Your Interest
        </button>

        <NewForm />
      </div>
    </div>
  );
};

export default InfoBox;
