import "./App.css";
// import AboutCapitalAcres from "./components/AboutCapitalAcres";

import Amenities from "./components/Amenities";
import BestQuotes from "./components/BestQuotes";
import Carousel from "./components/Carousel";
import Details from "./components/Details";

import FloorPlan from "./components/FloorPlan";
import Footer from "./components/Footer";
import Form from "./components/Form";
import Header from "./components/Header";
import Hero from "./components/Hero";
import InfoBox from "./components/InfoBox";
import StripSection from "./components/StripSection";
import Pricing from "./components/Pricing";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <InfoBox />
      <StripSection />
      <Details />

      <Pricing />
      <FloorPlan />
      <Amenities />

      <Carousel />

      {/* <AboutCapitalAcres /> */}

      <BestQuotes />
      <Footer />
      <Form />
    </div>
  );
}

export default App;
